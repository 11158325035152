<template>
  <div class="index">
    <Tip @childFn="parentFn"></Tip>
    <div class="top" :style="topNode">
      <div class="record" @click="record">领取记录</div>
      <div class="conts">
        <div class="conts-title">
          <div class="conts-title-crown" :style="contsTitleNode"></div>
          <div class="conts-title-text">
            <span
                class="conts-title-text-icon left"
                :style="contsTitleLeft"
            ></span>
            <span class="conts-title-text-text">以下特权任选一</span>
            <span
                class="conts-title-text-icon right"
                :style="contsTitleRight"
            ></span>
          </div>
        </div>

        <ul class="conts-lists">
          <li
              @click="prerogative(item, index)"
              class="conts-lists-item"
              :class="{ choiced: index === current }"
              v-for="(item, index) in this.$store.state.iconList"
              :key="index"
          >
            <div
                class="conts-lists-item-icon"
                :style="{
                backgroundImage: 'url(' + item.imgPath + ')',
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
                borderRadius: '50%',
                transform: 'scale(.9)',
              }"
            ></div>
            <div class="conts-lists-item-text">
              <p>{{ item.text }}</p>
              <p>{{iconText}}</p>
            </div>
          </li>
        </ul>
        <div class="conts-choice">
          <span class="conts-choice-icon"></span>
          <span class="conts-choice-text">{{ getText }}</span>
        </div>

        <div class="conts-infos">
          <p class="conts-infos-label">手机帐号</p>
          <p class="conts-infos-phone">{{ this.$store.state.phone }}</p>
        </div>
        <div v-show="hide" style="padding: 0 0.9rem">
          <p class="conts-infos-label">QQ帐号</p>
          <div class="conts-infos-account">
            <input
                type="tel"
                v-model="qqNum"
                placeholder="务必输入正确QQ号码"
                class="conts-infos-account-input"
                maxlength="10"
            />
          </div>
        </div>
        <div
            v-show="!hide"
            ref="sureBtn"
            class="conts-infos-button conts-infos-button-unconfirmed"
            @click="openVip">
          <i></i><span>确认领取</span>
        </div>
        <div
            v-show="hide"
            :class="
            isActive === false
              ? 'active conts-infos-button': ' conts-infos-button-sure conts-infos-button'
          "
            @click="openVip">
          <i></i><span>确认领取</span>
        </div>
      </div>


      <div class="rules">
        <div class="rules-lists">
          <div class="rules-title">· 权益购买须知 ·</div>
          <div class="rules-text">
            <p class="rules-txt">
              1、每个手机号码限购1份，下单后立即激活会员特权。购买时务必填写核实正确的手机号码，如因手机号填写错误导致充值错误，由消费者自行承担。
            </p>
            <p class="rules-txt">2、本产品下单购买，不退不改，介意者勿拍！</p>
            <p class="rules-txt">
              3、下单前请您务必仔细阅读产品中各项说明，然后再下单订购。
            </p>
            <p class="rules-txt">
              4、下单购买后，则视为您已仔细阅读本产品中的各项说明，并认同此《购买须知》以及产品规则，否则责任自负，不在售后服务之列。
            </p>
          </div>
        </div>

        <div class="rules-lists">
          <div class="rules-title">使用规则</div>
          <div class="rules-text">
            <p class="rules-txt">
              1、使用规则:<br/>
              年卡：共有12个周期兑换VIP权益(即购买后12个月内有12次权益兑换机会)<br/>
<!--              半年卡：共有6个周期兑换VIP权益(即购买后6个月内有6次权益兑换机会)<br/>
              季卡：共有3个周期兑换VIP权益(即购买后3个月内有3次权益兑换机会)<br/>
              月卡：共有1个周期兑换VIP权益(即购买后1个月内有1次权益兑换机会)<br/>
              钻石版月卡：共4个周期兑换VIP权益(即购买后1个月内有4次权益兑换机会)<br/>
              周卡：共有1个周期兑换VIP权益(即购买后1个星期内有1次权益兑换机会)<br/>-->
            </p>
            <p class="rules-txt">
              2、为确保个人权益与信息安全，在兑换权益周期内须本人填写【手机号码】+【验证码】的形式登录领取兑换；可以选择周期内任意一款产品，需每月验证本人使用去兑换，预期未兑换视为放弃兑换资格。
            </p>
          </div>
        </div>
        <div class="rules-lists">
          <div class="rules-title">· 注意事项 ·</div>
          <div class="rules-text">
            <p class="rules-txt">
              1、每个周期每次兑换会员时只能选择一个会员。
            </p>
            <p class="rules-txt">
              2、每次选择会员时务必填写购买时的手机号码; 腾讯视频、QQ音乐、QQ会员要求填写正确的QQ号码，该三项权益要求通过QQ账号登录。
            </p>
            <p class="rules-txt">
              3、本商品只能在手机、电脑、ipad上使用，均非TV版。
            </p>
            <p class="rules-txt">
              4、若24小时后还未激活，请及时联系客服。
            </p>
            <p class="rules-txt">
              5、每月N个会员可相互转换/持续兑换一种，每月限转1次。
            </p>
            <p class="rules-txt">
              6、仅限注册会员购买使用，无法为其他手机号购买，可领取的权益均非TV版。
            </p>
            <p class="rules-txt">
              7、套餐内容如有变动或需要套餐外增值服务，费用自理，详情请咨询客服。<br>
              客服电话:18666017481;服务时间:工作日9:00-18:00
            </p>
            <br/>
            <div class="rules-txt" style="text-align: center;">@再芮股份</div>
          </div>
        </div>
      </div>


      <van-popup
          :close-on-click-overlay="false"
          v-model="popShow"
          :style="{
          width: '65%',
          height: '30%',
          borderRadius: '12px',
          padding: '48px 30px 20px',
        }">
        <div class="popipContent">
          <div class="item title">温馨提醒</div>
          <div class="item">
            您领取的是 <span class="note">{{ text }}</span>会员
          </div>
          <div class="item">
            QQ号码：<span class="note">{{ qqNum }}</span>
          </div>
          <div class="item remark">注：一旦领取不可退改</div>
          <div class="item btn">
            <van-button size="small" type="danger" @click="cancel">返回修改</van-button>
            <van-button size="small" type="info" @click="receiveVip">确认领取</van-button>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import Tip from "../components/Message";
import {mapActions, mapState} from "vuex";
import {Dialog, Toast} from "vant";

export default {
  name: "Index",
  components: {
    Tip,
  },
  computed: {
    ...mapState(["vueConfig", "token", "eligibilityId","iconList"])
  },
  data() {
    return {
      qqNum: "",
      current: null,
      PhoneNumber: null,
      hide: false,
      isActive: false,
      getText: "选择要领取的特权",
      text: null,
      num: 0,
      id: "",
      info: {
        PhoneNumber: "",
        getText: "选择要领取的特权",
        qqNum: "",
      },
      topNode: {
        //backgroundImage: "url(" + require("../assets/image/"+this.$store.state.vueConfig.bgImg) + ")",
        backgroundImage: "url(" + require("../assets/image/bg.jpg") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% auto",
        backgroundColor: "#fff5ea",
      },
      contsTitleNode: {
        backgroundImage:
            "url(" + require("../assets/image/conts-title.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto 100% ",
        backgroundPosition: "center center",
        height: "2rem",
        marginBottom: "0.3rem",
      },
      contsTitleLeft: {
        width: "5.15rem",
        height: "1.5rem",
        backgroundPosition: "0 0",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundImage: "url(" + require("../assets/image/left.png") + ")",
      },
      contsTitleRight: {
        width: "5.15rem",
        height: "1.5rem",
        backgroundPosition: "0 0",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundImage: "url(" + require("../assets/image/right.png") + ")",
      },
      iconText:null,
      iconListMonth: [
        {
          imgPath: require("../assets/image/qq_vip.png"),
          text: "QQ",
          qqNum: true,
          productCode: "QQVIP-M001",
        },
       /* {
          imgPath: require("../assets/image/qq_music.png"),
          text: "QQ音乐豪华",
          qqNum: true,
          productCode: "QQMUSIC-M001",
        },*/
        {
          imgPath: require("../assets/image/kugou.png"),
          text: "酷狗音乐豪华",
          productCode: "KUGOU-M001",
        },
        {
          imgPath: require("../assets/image/mangguo.png"),
          text: "芒果TV",
          productCode: "MANGGUO-M001",
        },
        {
          imgPath: require("../assets/image/wangyiyun.png"),
          text: "网易云音乐包",
          productCode: "WYMUSIC-PACK-M001",
        },
        {
          imgPath: require("../assets/image/ximalaya.png"),
          text: "喜马拉雅巅峰",
          productCode: "XMLY-M001",
        },
        {
          imgPath: require("../assets/image/huangzuan.png"),
          text: "QQ空间黄钻",
          qqNum: true,
          productCode: "HUANGZUAN-M001",
        },
        {
          imgPath: require("../assets/image/yinyuebao.png"),
          text: "QQ音乐包",
          qqNum: true,
          productCode: "QQ-MUSIC-PACK-M001",
        },
        {
          imgPath: require("../assets/image/meituan.png"),
          text: "美团外卖红包10元",
          productCode: "MEITUAN-TEN",
        },
        /*{
          imgPath: require("../assets/image/baidu_wenku.png"),
          text: "百度文库",
          productCode: "BAIDU-WENKU-M001",
        },*/
        {
          imgPath: require("../assets/image/baidu_wangpan.png"),
          text: "百度网盘",
          productCode: "BAIDU-WANGPAN-M001",
        },
        {
          imgPath: require("../assets/image/nangua.png"),
          text: "南瓜电影",
          productCode: "NANGUA-M001",
        },
        /*{
          imgPath: require("../assets/image/aiqiyi.png"),
          text: "爱奇艺视频",
          productCode: "IQIYI-M001",
        },*/
        {
          imgPath: require("../assets/image/youku.png"),
          text: "优酷黄金VIP",
          productCode: "YOUKUVIP-M001",
        },
        {
          imgPath: require("../assets/image/kuwo.png"),
          text: "酷我音乐豪华VIP",
          productCode: "KUWO-M001",
        },
        /* {
           imgPath: require("../assets/image/tengxun.png"),
           text: "腾讯视频VIP",
           qqNum: true,
           productCode: "VQQVIP-M001",
         },*/
        {
          imgPath: require("../assets/image/pptv.png"),
          text: "PPTV视频",
          productCode: "PPTV-M001",
        },
        {
          imgPath: require("../assets/image/souhu.png"),
          text: "搜狐视频",
          productCode: "SOHU-M001",
        },
        /*{
          imgPath: require("../assets/image/1905.png"),
          text: "1905电影VIP",
          productCode: "1905-M001",
        },*/
        {
          imgPath: require("../assets/image/maiduidui.png"),
          text: "埋堆堆视频VIP",
          productCode: "MAIDUIDUI-M001",
        },
        {
          imgPath: require("../assets/image/migu.png"),
          text: "咪咕阅读",
          productCode: "MIGU-M001",
        },
        /*{
          imgPath: require("../assets/image/aiqiyi_ty.png"),
          text: "爱奇艺体育大众",
          productCode: "AIQIYITY-M001",
        },*/
        {
          imgPath: require("../assets/image/zhihu.png"),
          text: "知乎读书",
          productCode: "ZHIHU-M001",
        },
        {
          imgPath: require("../assets/image/momo.png"),
          text: "陌陌",
          productCode: "MOMO-M001",
        }/*,
        {
          imgPath: require("../assets/image/qingting.png"),
          text: "蜻蜓FM",
          productCode: "QINGTING-M001",
        }*/
      ],
      rulesList: [
        {
          title: " ·权益购买规则· ",
        },
      ],
      openVipForm: {
        token: null,
        batchCode: null,
        productCode: null,
        eligibilityId: null,
        account: null
      },
      tag: true,
      popShow: false,
      wechatPayParams: {
        code: null
      }
    };
  },
  beforeCreate() {
    try {
      //获取URL路径
      let index = this.$route.query.index;
      if(index === undefined){
        index = this.$route.query.state;
      }
      if (index !== undefined) {
        this.axios.post('/apiVueConfig/getByIndex', {index: index}, {headers: {'Content-Type': 'application/json'}})
            .then(res => {
                  if (res.data.data.code === 71000 && res.data.data.vueConfig !== null) {
                    this.asyncSetVueConfig(res.data.data.vueConfig);
                    //动态设置背景图
                    this.setBgImg(res.data.data.vueConfig.bgImg);
                    //设置图标
                    this.setIcon(res.data.data.vueConfig.vipGroupBatch);
                    //获取token
                    this.axios.post('/apiUser/loginByUsername', {username: this.vueConfig.username},
                        {headers: {'Content-Type': 'application/json'}})
                        .then(res => {
                              if (res.data.data.code === 11010) {
                                this.asyncSetToken(res.data.data.token);
                              } else {
                                Dialog({title: '提示', message: res.data.data.message});
                              }
                            }
                        ).catch(error => {
                      Dialog({title: '提示', message: error});
                    })
                  } else {
                    this.showError();
                  }
                }
            ).catch(error => {
          Dialog({title: '提示', message: error});
        })
      } else {
        this.showError();
      }
    } catch (e) {
      this.showError();
    }
  },
  methods: {
    ...mapActions(['asyncSetVueConfig', 'asyncSetCode','asyncSetToken', 'asyncSetEligibilityId', 'asyncSetPhone']),
    showError() {
      Dialog
          .alert({title: '系统提示', message: "链接出错，无法访问，请刷新重试或反馈"}).then(() => {
        //出现空白页
        window.opener = null;
        window.open("about:blank", "_top").close();
      });
    },
    setBgImg(img) {
      this.topNode.backgroundImage = "url(" + require("../assets/image/" + img) + ")";
    },
    setIcon(vipGroupBatch) {
      switch (vipGroupBatch) {
        case "LEEK_MEMBER_YEAR":
          this.$store.state.iconList = this.iconListMonth;
          this.iconText = "会员月卡";
          break;
      }
    },
    record() {
      this.$router.push({
        path: `/record`,
      });
    },
    prerogative(item, index) {
      this.openVipForm.productCode = item.productCode;
      this.text = item.text;
      if (item.text) {
        this.$refs.sureBtn.className =
            "conts-infos-button conts-infos-button-sure";
      }
      this.current = index;
      this.getText = `领取${item.text + this.iconText}`;
      item.qqNum ? (this.hide = true) : (this.hide = false);
      if (!item.qqNum) {
        this.qqNum = "";
      }
    },
    parentFn(phone) {
      this.PhoneNumber = phone
          .replace(/\s/g, "")
          .replace(/(\d{3})(\d{0,4})(\d{0,4})/, "$1 $2 $3");
    },
    openVip() {
      if (this.getText === "选择要领取的特权") {
        Toast("请选择要领取的特权");
        return;
      }
      if ((this.openVipForm.productCode === 'QQVIP-M001' || this.openVipForm.productCode === 'QQMUSIC-M001' ||
          this.openVipForm.productCode === 'VQQVIP-M001') && !this.isActive) {
        Toast("请输入正确的QQ号");
        return;
      }
      if (this.qqNum) {
        this.popShow = true;
      } else {
        this.receiveVip();
      }
    },
    cancel() {
      this.popShow = false;
      this.tag = true;
    },
    receiveVip() {
      this.openVipForm.token = this.$store.state.token;
      this.openVipForm.batchCode = this.$store.state.vueConfig.vipGroupBatch;
      this.openVipForm.eligibilityId = this.$store.state.eligibilityId;
      this.openVipForm.phone = this.$store.state.phone;
      if (this.qqNum !== "") {
        this.openVipForm.account = this.qqNum;
      } else {
        this.openVipForm.account = this.$store.state.phone;
      }

      this.axios.post('/apiDistributeOrder/add', this.openVipForm, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        let message;
        if (res.data.data.code === 31000) {
          message = "订单生成成功，预计5分钟内到账";
        } else if (res.data.data.code === 31013 || res.data.data.code === 31007) {
          message = "订单生成失败，请重试或反馈";
        } else {
          message = res.data.data.message;
          //message = "订单生成失败，请重试或反馈";
        }
        Dialog.alert({
          title: "提示",
          message: message,
        }).then(() => {
          this.popShow = false;
          this.tag = true;
        })
      }).catch(error => {
        console.log(error);
      });
    },
  },
  watch: {
    qqNum(newNum) {
      /^[1-9][0-9]{4,9}$/.test(newNum) ? (this.isActive = true) : (this.isActive = false);
      /^[1-9][0-9]{4,9}$/.test(newNum) ? (this.tag = true) : (this.tag = false);
    }
  },
};
</script>

<style lang="scss" scoped>
.index {
  box-sizing: border-box;
}

.top {
  padding: 7.5rem 0 1rem;

  .record {
    position: fixed;
    right: 0;
    top: 1.4rem;
    color: #fff;
    padding: 0.3rem 0.55rem 0.3rem 0.3rem;
    font-size: 0.6rem;
    background-color: rgba(126, 88, 42, 0.7);
    border-top-left-radius: 2.5rem;
    border-bottom-left-radius: 2.5rem;
  }

  .conts {
    margin: 2.5rem 0.7rem 0;
    padding: 0.75rem;
    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 0 0.15rem 0.75rem 0 rgb(211 155 93 / 81%);

    .form-onlyRead {
      pointer-events: none;
    }

    .conts-title {
      padding-top: 0.4rem;

      .conts-title-text {
        text-align: center;

        .conts-title-text-text {
          color: #7a4528;
          font-size: 0.8rem;
          font-weight: 600;
          margin: 0 1rem;
        }

        span {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    .conts-lists {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0.6rem;
      margin-bottom: 0.9rem;

      .conts-lists-item {
        width: 32%;
        height: 3.65rem;
        padding-top: 0.4rem;
        padding-bottom: 3rem;
        border-radius: 0.3rem;

        &.choiced {
          background-color: #fff5ea;
        }

        .conts-lists-item-icon {
          width: 2.8rem;
          height: 2.8rem;
          margin: 0 auto 0.35rem auto;
        }

        .conts-lists-item-text {
          color: #222;
          font-size: 0.75rem;
          text-align: center;

          p {
            margin: 0.5rem 0;
          }
        }
      }
    }

    .conts-choice {
      margin-bottom: 1rem;

      span {
        display: inline-block;
        vertical-align: middle;
      }

      .conts-choice-icon {
        width: 1.88rem;
        height: 1.5rem;
        margin-right: 0.4rem;
        margin-left: -0.8rem;
        border-top-right-radius: 1.05rem;
        border-bottom-right-radius: 1.05rem;
        background: linear-gradient(90deg, #e29a4a, #c07e28);
      }

      .conts-choice-text {
        color: #ca8700;
        font-size: 1.25rem;
        font-weight: 700;
      }
    }

    .conts-infos {
      padding: 0 0.9rem;

      .conts-infos-label {
        color: #1d1d1d;
        font-size: 1rem;
        margin-bottom: 1rem;
      }

      .conts-infos-phone {
        color: #222;
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 1rem;
      }
    }

    .conts-infos-button-sure {
      background-color: #d69d4c;
    }

    .conts-infos-button-unconfirmed {
      background-color: #a7a7a7;
    }

    .active {
      background-color: #a7a7a7;
    }

    .conts-infos-button {
      height: 3.25rem;
      line-height: 3.25rem;
      margin: 0 auto;
      text-align: center;
      border-radius: 2.25rem;

      span {
        font-size: 1.25rem;
        color: #fff;
      }
    }

    .conts-infos-account {
      margin-bottom: 1rem;
      padding-bottom: 0.3rem;
      border-bottom: 1px solid #ebebeb;
    }

    .conts-infos-label {
      color: #1d1d1d;
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    .conts-infos-account-input {
      width: 300px;
      border: 0;
      margin: 0;
      padding: 0;
      color: #222;
      font-size: 1.5rem;
      font-weight: 600;
      vertical-align: middle;
      outline: none;
    }
  }

  .rules {
    padding: 0 1.25rem;

    .rules-lists {
      padding-top: 1.5rem;

      .rules-title {
        color: #222;
        font-size: 1.25rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 1rem;
      }

      .rules-text {
        .rules-txt {
          color: #666;
          font-size: 0.95rem;
          line-height: 1.6;
          margin-bottom: 0.25rem;
        }

        .txt-color {
          color: #bc7714;
        }
      }
    }
  }

  .popipContent {
    //background: #666;
    width: 100%;

    .item {
      margin-bottom: 0.7rem;
      font-size: 1rem;
      color: #666;
    }

    .note {
      color: #b22222;
    }

    .title {
      color: #222;
      font-size: 1.2rem;
      text-align: center;
      padding-bottom: 1rem;
    }

    .remark {
      width: 85%;
      color: red;
      text-align: right;
      font-size: 0.5rem;
      margin-top: 1.4rem;
    }

    .btn {
      margin: 0 auto;
      width: 70%;
      //background: #666;
      padding: 0.2rem 1rem 1rem;
      display: flex;
      justify-content: space-between;

      .van-button:nth-child(1) {
        // margin: 1.8rem;
      }
    }
  }
}
</style>
