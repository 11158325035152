<template>
  <div>
    <dingtalk></dingtalk>
    <van-popup :close-on-click-overlay="false" v-model="showDialog">
      <a v-if="this.$store.state.vueConfig.buyUrl !== null" v-bind:href="this.$store.state.vueConfig.buyUrl">购买</a>
      <a v-if="this.$store.state.vueConfig.kefuUrl !== null" v-bind:href="this.$store.state.vueConfig.kefuUrl">客服</a>
      <br v-if="this.$store.state.vueConfig.buyUrl !== null || this.$store.state.vueConfig.kefuUrl !== null">
      <br>
      <h2>确认会员身份(已购用户验证后领取)</h2>
      <div class="content" v-if="showPhone">
        <input
            ref="testDom"
            maxlength="11"
            v-model="phoneNumber"
            type="tel"
            placeholder="请输入下单手机帐号"/>
      </div>
      <van-button @click="validatePhone" :color="showColor" round type="info" :disabled="disable" v-if="showPhone">
        <span>确认</span></van-button>
      <div class="content" v-if="showValidate">
        <input
            ref="testDom"
            maxlength="6"
            v-model="validateCode"
            type="tel"
            placeholder="请输入手机短信验证码"/>
      </div>
      <van-button @click="validatePhoneCode" :color="codeColor" round type="info" :disabled="codeDisable"
                  v-if="showValidate"><span>验证</span></van-button>
      <!--      <div class="content" v-if="showBuy">
              <h5 style="font-size:50%;text-align: center" @click="showPop">购买即认同此《购买须知》以及产品规则</h5><br>
              <input
                  ref="testDom"
                  maxlength="11"
                  v-model="phoneBuyNum"
                  type="tel"
                  placeholder="请输入下单手机帐号"/>
            </div>
            <van-button @click="goToBuy" :color="buyColor" round type="info" :disabled="buyDisable" v-if="showBuy">
              <span>购买</span></van-button>-->
    </van-popup>
    <!--    <van-popup v-model="showPopup" round position="bottom" :style="{ height:'60%'}">
          <div class="rules" style="font-size:90%;width: 90%;position: relative;left: 5%;line-height: 2em;">
            <div class="rules-lists">
              <br>
              <div class="rules-title" style="text-align: center">· 权益购买须知 ·</div>
              <br>
              <div class="rules-text">
                <p class="rules-txt">
                  1、每个手机号码限购1份，下单后立即激活会员特权。购买时务必填写核实正确的手机号码，如因手机号填写错误导致充值错误，由消费者自行承担。
                </p>
                <p class="rules-txt">2、本产品下单购买，不退不改，介意者勿拍！</p>
                <p class="rules-txt">
                  3、下单前请您务必仔细阅读产品中各项说明，然后再下单订购。
                </p>
                <p class="rules-txt">
                  4、下单购买后，则视为您已仔细阅读本产品中的各项说明，并认同此《购买须知》以及产品规则，否则责任自负，不在售后服务之列。
                </p>
              </div>
            </div>
            <br>
            <div class="rules-lists">
              <div class="rules-title" style="text-align: center">兑换及使用规则</div>
              <br>
              <div class="rules-text">
                <p class="rules-txt">
                  1、使用方法：购买韭菜会员后，微信打开搜索【再芮股份】微信公众号-【韭菜会员领取】轮播框-输入购买【手机号码】+【验证码】登录。即可兑换9大影音APP的特权VIP之一，通过兑换成功的手机号码登录对应的
                  影音APP/官网，即可享受VIP权益。
                </p>
                <p class="rules-txt">
                  2、使用规则:购买会员成功后，共有6个周期兑换VIP权益(即购买后6个月内有6次权益兑换机会) : 每30天为一个周期，每个周期内可以兑换一次9大会员里任意一个VIP权益，指定周期内如未兑换视为过期，
                  放弃当月兑换资格，下一周期可继续领取。
                </p>
                <p class="rules-txt">
                  3、为确保个人权益与信息安全，在兑换权益周期内须本人填写【手机号码】+【验证码】的形式登录领取兑换；可以选择周期内任意一款产品，需每月验证本人使用去兑换，预期未兑换视为放弃兑换资格。
                </p>
              </div>
            </div>
            <br>
            <div class="rules-lists">
              <div class="rules-title" style="text-align: center">· 注意事项 ·</div>
              <br>
              <div class="rules-text">
                <p class="rules-txt">1、每个周期每次兑换会员时只能选择一个会员;</p>
                <p class="rules-txt">
                  2、每次选择会员时务必填写购买时的手机号码; 腾讯视频、QQ音乐、QQ会员要求填写正确的QQ号码，该三项权益要求通过QQ账号登录;
                </p>
                <p class="rules-txt">
                  3、本商品只能在手机、电脑、ipad上使用，均非TV版;
                </p>
                <p class="rules-txt">4、若24小时后还未激活，请及时联系客服;</p>
                <p class="rules-txt">
                  5、每月9个会员可相互转换/持续兑换一种，每月限转1次;
                </p>
                <p class="rules-txt">
                  6、仅限注册会员购买使用，无法为其他手机号购买，可领取的权益均非TV版;
                </p>
                <p class="rules-txt">
                  7、套餐内容如有变动或需要套餐外增值服务，费用自理，详情请咨询客服。
                </p>
                <br/>
              </div>
            </div>
          </div>
        </van-popup>-->
  </div>

</template>
<script src="https://open.95516.com/s/open/js/upsdk.js" type="text/javascript"></script>
<script>
import {mapState, mapActions} from 'vuex'
import {Dialog, Toast} from "vant";
import Wechat from "../js/wechat";

export default {
  name: "Message",
  components: {
    'dingtalk': {
      render(createElement) {
        return createElement(
            'script',
            {
              attrs: {
                type: 'text/javascript',
                src: 'https://open.95516.com/s/open/js/upsdk.js',
              },
            },
        );
      },
    },
  },
  computed: {
    ...mapState(["vueConfig", "token", "eligibilityId","iconList"])
  },
  data() {
    return {
      showDialog: true,
      showPhone: true,
      showValidate: false,
      showBuy: false,
      isPhone: false,
      phoneNumber: null,
      passId: [192583,192592,192593,192603,192616,192734,192750],
      wechatOrder: {
        token: null,
        code: null,
        description: null,
        attach: null,
        notifyUrl: "https://leekmember.zairui-vip.com/apiWechatPayJsapiOrderController/notify",
        //notifyUrl: "http://4326h82o32.qicp.vip/apiWechatPayJsapiOrderController/notify",
        phone: null
      },
        iconListOldUser: [
      {
        imgPath: require("../assets/image/aiqiyi.png"),
        text: "爱奇艺视频",
        productCode: "IQIYI-M001",
      },

      {
        imgPath: require("../assets/image/tengxun.png"),
        text: "腾讯视频VIP",
        qqNum: true,
        productCode: "VQQVIP-M001",
      }
    ],
      unionpayOrder:{
        token: null,
        phone: null,
        batchCode:null
      },

      loginForm: {
        username: "jchy"
      },
      dataList: [],
      toBuy: false,
      phoneBuyNum: null,
      phoneShow: true,
      showPopup: false,
      buy: false,
      show: false,
      validate: false,
      validateCode: null,
      validateShow: false,
      buyShow: false,
      showColor: "#a7a7a7",
      codeColor: "#a7a7a7",
      buyColor: "#a7a7a7",
      phoneVerify: 19812005147,
      eId: null,
      disable: false,
      buyDisable: false,
      codeDisable: false,
      buyLoginForm: {
        phone: "",
        token: "",
        code: ""
      },
      validateForm: {
        token: "",
        phone: "",
        code: ""
      },
      payForm: {
        token: '',
        phone: '',
        vipGroupBatch: 'jiuCaiHalfAnnual'
      },
    };
  },
  methods: {
    ...mapActions(['asyncSetVueConfig', 'asyncSetToken', 'asyncSetEligibilityId', 'asyncSetPhone','asyncAddIconList']),
    showError() {
      Dialog
          .alert({title: '系统提示', message: "链接出错，无法访问，请刷新重试或反馈"}).then(() => {
        //出现空白页
        window.opener = null;
        window.open("about:blank", "_top").close();
      });
    },
    validatePhone() {
      if (this.isPhone) {
        //按钮禁用,防止多次请求
        this.disable = true;
        this.axios.post('/apiDistributeEligibility/list',
            {'token': this.token, 'batchCode': this.$store.state.vueConfig.vipGroupBatch, 'phone': this.phoneNumber},
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }).then(res => {
          this.disable = false;
          if (res.data.data.code !== 21000 && res.data.data.code !== 21004) {
            Dialog({title: '提示', message: res.data.data.message});
          } else if (res.data.data.code === 21004 || res.data.data['listSize'] === 0) {
            //没有领取资格，判断是否需要调起云闪付支付
            if (this.$store.state.vueConfig.wechatPay === 3) {
              Dialog.confirm({message: '没有更多领取资格，是否购买？'}).then(() => {
                this.addUnionpayOrder();
              }).catch(() => {
                //关闭
              });
            } else {
              Toast('没有更多领取资格');
            }
          } else {
            this.eId = res.data.data.list[0].eligibilityId;
            if (this.$store.state.vueConfig.codeValidate) {
              //发送验证码
              this.axios.post('/apiPhoneMessageRecord/send', {'token': this.token, 'phone': this.phoneNumber}, {
                headers: {
                  'Content-Type': 'application/json'
                }
              }).then(res => {
                this.disable = false;
                if (res.data.data.code === 51000) {
                  this.showPhone = false;
                  this.showValidate = true;
                  Toast('验证码发送成功，5分钟有效');
                } else {
                  Dialog({message: res.data.data.message});
                }
              }).catch(error => {
                console.log(error);
              });
            } else {
              //如果没有其他提示信息
              if (this.$store.state.vueConfig.enterMessage === '#') {
                Toast('验证成功');
                if(this.passId.includes(this.eId)){
                  this.asyncAddIconList(this.iconListOldUser);
                }
                this.asyncSetEligibilityId(this.eId);
                this.asyncSetPhone(this.phoneNumber);
                this.showDialog = false;
              } else {
                this.asyncSetEligibilityId(this.eId);
                this.asyncSetPhone(this.phoneNumber);
                this.showDialog = false;
                Dialog({title: '提示', message: this.$store.state.vueConfig.enterMessage});
              }
            }
          }
        }).catch(error => {
          Dialog({title: '提示', message: error});
        })
      }
    },
    validatePhoneCode() {
      if (this.validate) {
        this.axios.post('/apiPhoneMessageRecord/validate', {
          'token': this.token,
          'phone': this.phoneNumber,
          'code': this.validateCode
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          if (res.data.data.code !== 51000) {
            Toast(res.data.data.message);
            this.disable = false;
            //验证码验证成功
          } else {
            //如果没有其他提示信息
            if (this.$store.state.vueConfig.enterMessage === '#') {
              Toast('验证成功');
              this.asyncSetEligibilityId(this.eId);
              this.asyncSetPhone(this.phoneNumber);
              this.showDialog = false;
            } else {
              this.asyncSetEligibilityId(this.eId);
              this.asyncSetPhone(this.phoneNumber);
              this.showDialog = false;
              Dialog({title: '提示', message: this.$store.state.vueConfig.enterMessage});
            }
            /*this.asyncSetEligibilityId(this.eId);
            this.asyncSetPhone(this.phoneNumber);
            this.showDialog = false;
            Dialog({title: '提示', message: '由于系统问题，腾讯视频、爱奇艺视频暂时无法兑换，建议您领取其它会员'});*/
          }
        }).catch(error => {
          console.log(error);
        });
      }
    },addUnionpayOrder(){

      this.unionpayOrder.token = this.$store.state.token;
      this.unionpayOrder.phone = this.phoneNumber;
      this.unionpayOrder.batchCode = this.$store.state.vueConfig.vipGroupBatch;
      console.log("this:");
      console.log(this.unionpayOrder);
      this.axios.post(
          "/apiUnionPayAppOrderController/add",
            this.unionpayOrder
        ,
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
      ).then((result) => {
        console.log(result);
        console.log("upsdk9");

        console.log(upsdk);
        if (result.data.data.code===31000) {
       // if (result.data.respCode==="00") {
          //确认调起微信支付购买

          console.log("调用云闪付")

          var tnNo=result.data.detail.tn;
          // var tnNo=result.data.tn;
          console.log(tnNo);
          upsdk.pluginReady (function() {
            upsdk.pay({
              tn: tnNo,
              success: function () {
                console.log("支付成功!");

                // 支付成功, 开发者执行后续操作。
              },
              fail: function (err) {
                console.log("支付失败!");
                // 支付失败, err.msg 是失败原因描述, 比如TN号不合法, 或者用户取消了交易 等等。
              }
            });
          })
        } else {
          Dialog.confirm({
            title: '系统反馈',
            message: result.data.data.message,
            showConfirmButton: false,
            cancelButtonText: '关闭'
          }).catch(() => {
            /*关闭*/
          });
        }
      });
    }
    ,
    addWechatOrder() {
      this.wechatOrder.token = this.$store.state.token;
      this.wechatOrder.code = this.$store.state.code;
      this.wechatOrder.description = "韭菜会员订单【" + this.$store.state.vueConfig.vipGroupBatch + "】";
      this.wechatOrder.attach = "{\"batchCode\":\"" + this.$store.state.vueConfig.vipGroupBatch + "\"}";
      this.wechatOrder.phone = this.phoneNumber;
      this.axios.post(
          "/apiWechatPayJsapiOrderController/add",
          this.wechatOrder,
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
      ).then((result) => {
        if (result.data.data.code === 31000) {
          //确认调起微信支付购买
          Wechat.pay(result.data.data.detail);
        } else {
          Dialog.confirm({
            title: '系统反馈',
            message: result.data.data.message,
            showConfirmButton: false,
            cancelButtonText: '关闭'
          }).catch(() => {
            /!*关闭*!/
          });
        }
      });
    }
  },
  watch: {
    phoneNumber(newPhone) {
      /^[1][3-9][0-9]{9}$/.test(newPhone) ? this.showColor = "#d69d4c" : this.showColor = "#a7a7a7";
      /^[1][3-9][0-9]{9}$/.test(newPhone) ? this.isPhone = true : this.isPhone = false;
    },
    phoneBuyNum(newBuyPhone) {
      /^[1][3-9][0-9]{9}$/.test(newBuyPhone) ? this.buyColor = "#d69d4c" : this.buyColor = "#a7a7a7";
      /^[1][3-9][0-9]{9}$/.test(newBuyPhone) ? this.isPhone = true : this.isPhone = false;
    },
    validateCode(newCode) {
      /^[0-9]{6}$/.test(newCode) ? this.codeColor = "#d69d4c" : this.codeColor = "#a7a7a7";
      /^[0-9]{6}$/.test(newCode) ? this.validate = true : this.validate = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.van-popup--center {
  width: 85%;
  padding: 1.2rem;
  margin-top: -4.8rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  background-color: #fff;

  h2 {
    color: #222;
    font-size: .95rem;
    font-weight: 600;
    line-height: 1.1rem;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  .content {
    padding: 0.9rem 0;
    margin-bottom: 1rem;
    border-bottom: 1px solid #ebebeb;

    input {
      width: 100%;
      padding: 0;
      font-size: 1rem;
      text-align: center;
      vertical-align: middle;
      border: 0;
    }
  }

  .van-button--round {
    width: 100%;
    height: 2.8125rem;
    border: 0;
    border-radius: 2.25rem;
    font-size: 1rem;
    text-align: center;
  }
}

.online-service {
  float: right;
  cursor: pointer;
  line-height: 1rem;
  font-size: 1rem;
  padding-right: .5rem;
}

a {
  color: #0066ff;
  margin-left: 2.4rem;
  float: right;
}
</style>
